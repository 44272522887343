<template>
  <div>
    <div class="page-body">
      <title-bar title="角色管理">
        <el-button type="primary" @click="edit()" icon="fa fa-plus-circle" :loading="loading">
          添加
        </el-button>
      </title-bar>
      <el-form ref="queryForm" :model="queryForm" inline label-width="100px">
        <el-form-item label="角色名称">
          <el-input type="text" v-model="queryForm.roleName" maxlength="50"
                    placeholder="请输入角色名称模糊查询" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-select v-model="queryForm.valid" clearable @change="query">
            <el-option value="1" label="是"></el-option>
            <el-option value="0" label="否"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="button-container" label-width="0px">
          <el-button type="primary" @click="query()" icon="fa fa-search" :loading="loading">
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <el-table stripe :data="records" :border="true" v-loading="loading">
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="roleCode" label="角色编码" align="center"></el-table-column>
        <el-table-column prop="roleName" label="角色名称" align="center"></el-table-column>
        <el-table-column prop="roleType" label="角色类型" align="center">
          <template slot-scope="{ row, $index }">
            <span v-if="row.sysRole">系统角色</span>
            <span v-else>用户角色</span>
          </template>
        </el-table-column>
        <el-table-column prop="valid" label="状态" align="center" width="70">
          <template slot-scope="{ row, $index }">
            <i class="fa fa-check-circle color-success" v-if="row.valid"></i>
            <i class="fa fa-times-circle color-error" v-else></i>
          </template>
        </el-table-column>
        <el-table-column prop="log" label="操作" width="120" align="center">
          <template slot-scope="{ row, $index }">
            <el-button type="primary" size="small" @click="edit(row)"
                       :disabled="row.sysRole">
              编辑
            </el-button>
            <el-button type="danger" size="small" @click="del(row)"
                       :disabled="row.sysRole">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";
import TitleBar from "@/components/base/TitleBar";
import TablePagination from "@/components/base/TablePagination";
import CodeSelect from "@/components/base/CodeSelect";
import Store from "@/utils/Store";

export default {
  components: {CodeSelect, TitleBar},
  extends: BaseVue,
  data() {
    return {
      records: [],
      queryForm: {
      }
    }
  },
  created() {
    this.query();
  },
  methods: {
    loadData() {
      this.loading = true;
      this.postForm("/role/list", this.queryForm, true).then((dr) => {
        this.records = dr.rows || [];
      }).finally(() => {
        this.loading = false;
      });
    },
    query() {
      this.loadData();
    },
    //修改
    edit(row) {
      this.$router.push({
        path: "/roleEdit",
        query: row
      });
    },
    //删除
    del(row) {
      this.$confirm("确定要删除选中角色吗？","系统提示", {type:"warning"}).then(() => {
        this.loading = true;
        this.postForm("/role/delete", {
          roleId: row.roleId
        }, true).then((dr) => {
          this.loadData();
        }).finally(() => {
          this.loading = false;
        });
      });
    }
  }
}
</script>

<style scoped lang="scss">
</style>
